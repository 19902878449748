@import "../../../App";

.homepage-container {
  width: 100%;
  //  height: 91vh;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  //@media screen and (min-width: 768px) {
  //  height: 91vh;
  //}

  .banner-play-btn {
    position: absolute;
    margin: auto;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    //display: none;
    //color: white;
    //background-color: $wtt-red;
    //font-size: 36px;
    // :hover {
    //   display: block !important;
    // }
  }

  img {
    object-fit: cover;
  }
}

.live-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  color: red;

  & > svg {
    width: 50px;
  }

  @media screen and (min-width: 768px) {
    top: 40px;
    right: 40px;

    & > svg {
      width: 76px;
    }
  }
}

.homepage-container:hover .banner-play-btn {
  display: block !important;
}

.details-container {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;

  &__close-icon {
    position: absolute;
    right: 20px;
    top: 40px;
  }
}

.homepage-section-2-text {
  width: 72%;
  margin: 0 auto;

  svg {
    margin-right: 6px;
  }

  button {
    background-color: $color-six;
    @include font-style($font-main, 700, 14px, 16px);
    padding: 9px 15px 6px;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    border-radius: 9999px;
    @include banner-h-color;
    margin-bottom: 10px;
    margin-top: 10px;

    &:hover {
      opacity: 1;
    }
  }

  @include banner-h-color;
  @include font-style($font-main, 400, 20px, 35px);

  padding: 15px 40px 48px 40px;

  .para {
    padding-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    color: $color-white;
    opacity: 75%;
    max-width: 1165px;
    @include font-style($font-main, 300, 20px, 35px);
  }

  .heading {
    padding-top: 16px;
    @include font-style($font-second, 800, 56px, 67px);
  }

  .time {
    @include font-style($font-second, 600, 24px, 24px);
    text-transform: uppercase;
  }
}

// @media (min-width: 1538px) {
//   .homepage-section-2-text {
//     max-width: 1165px;
//     margin-left: 8%;
//   }
// }

@media (min-width: 1024px) and (max-width: 1767px) {
  .homepage-section-2-text {
    width: 85%;
    padding: 48px auto 64px auto;
    @include font-style($font-main, 400, 16px, 28px);

    .heading {
      @include font-style($font-second, 800, 44px, 67px);
    }

    .time {
      @include font-style($font-second, 600, 24px, 24px);
    }
  }
}

//Tablets Responsive

@media (min-width: 768px) and (max-width: 1023px) {

  .black-rounded-btn-entry {
    padding: 14px 40px;
    font-size: 20px;
  }

  .homepage-section-2-text {
    width: 85%;
    padding: 48px 0 64px 0;
    @include font-style($font-main, 400, 16px, 28px);

    .heading {
      @include font-style($font-second, 800, 36px, 48px);
    }

    .time {
      @include font-style($font-second, 600, 24px, 24px);
    }
  }

}

//Mobile Responsive

@media (max-width: 767px) {
  .homepage-section-2-text {
    width: 85%;
    padding: 48px 0 64px 0;
    @include font-style($font-main, 400, 16px, 28px);

    .heading {
      @include font-style($font-second, 800, 36px, 48px);
    }

    .time {
      @include font-style($font-second, 600, 24px, 24px);
    }
  }
}