@import '../../Colors.scss';

.resent-events {
  position: relative;

  &__back-icon {
    width: fit-content;
    height: 16px;

    position: absolute;
    top: 42px;
    left: 50px;

    cursor: pointer;

    padding: 12px;
    background-color: #fff;
    border-radius: 50%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__search-bar-wrap {
    position: relative;
    margin: 140px auto 50px auto;

    width: 72%;
    box-sizing: border-box;

    @media (min-width: 1024px) and (max-width: 1768px) {
      width: 82%;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      width: 85%;
    }

    @media (max-width: 767px) {
      width: 90%;
      margin: 120px auto 0 auto;
    }
  }

  &__close-icon-wrap {
    position: absolute;
    right: 40px;
    top: 27px;

    cursor: pointer;

    @media screen and (max-width: 1024px) {
      right: 20px;
      top: 18px;
    }
  }

  &__search-bar {
    width: 100%;
    box-sizing: border-box;
    display: block;
    border-radius: 28px;
    background: #FFF;
    padding: 24px 40px;

    color: #2d2d2d;

    font-size: 36px;
    font-weight: 600;

    border: none;
    outline: none;

    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }
  }

  &__pagination-controller {
    position: relative;
    top: -80px;

    & > nav {
      width: fit-content;
      margin: 0 auto;

      & > ul {
        & > li:first-child {
          border-radius: 8px;
          background-color: #FFFFFF;
          position: absolute;
          left: 0;

          & > button {
            padding: 24px;
            margin: 0;
            border-radius: 8px;

            &::after {
              content: 'Vorherige';
              color: $color_seven;
              margin-left: 10px;
            }

          }
        }
      }

      & > ul {
        & > li:last-child {
          border-radius: 8px;
          background-color: #FFFFFF;

          position: absolute;
          right: 0;

          & > button {
            padding: 24px;
            margin: 0;
            border-radius: 8px;

            &::before {
              content: 'Nächste';
              color: $color_seven;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.event-with-pagination {
  width: 72%;
  margin: 0 auto;

  @media (min-width: 1024px) and (max-width: 1768px) {
    width: 82%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 85%;
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: auto;
  }

  & > div {
    width: auto;
  }
}