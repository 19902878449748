@import "../../../App";

.poster-description {
  background: $bgColor-event;
  height: 100%;
  width: 100%;

  .poster-desc-header {
    background: $color-background;
    min-height: 20%;
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding-left: 80px;

    .poster-clinic {
      color: rgba(40, 40, 40, 0.75);
      @include font-style($font-main, 300, 18px, 22px);

      &:after {
        content: '';
        display: block;
        margin-top: 16px;
        width: 110px;
        height: 1px;
        background:$bgColor-poster-clinic;
        border-radius: 3.5px;
      }
    }

    h4 {
      @include font-style($font-main, 700, 24px, 30px);

      text-align: start;
    }

    p {
      @include font-style($font-main, 300, 16px, 20px);

      span {
        color: rgba(40, 40, 40, 0.5);
        margin-left: 10px;
      }
    }

  }

  .poster-detail-head {
    @include flex("center", "center");
    gap: 20px;
  }

  .poster-detail-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    .video-with-title-container {
      height: 100%;
      //background: $color-background-container;
    }

    h3 {
      height: auto;
    }

    .video-desc-container {
      display: flex;
      width: 100%;
      height: 67%;
      background: $bgColor-video-desc-container;

      .hls-player-poster {
        //flex-grow: 1;
        display: flex;
        width: 50%;
        align-items: center;
        //background: $color-background-container;
        //color: $color-background;
        //flex-basis: 0;
        flex-direction: column;
        .hls-player-poster-container {
          width: 100%;
          height: 50%;
        }
        .qsk-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          margin-top: 32px;
          padding: 32px 40px;
          height: 50%;
          &__btn{
            align-self: end;
          }
          .span-poster-desc {
            margin-right: 32px;
            font-size: 20px;
          }

          .asc-question-poster {
            background: $bgColor-asc-question-poster;
            border-radius: 8px;
            align-self: flex-end;
            margin-top: 20px;
            @include p-for-btn-mui;
          }
        }
      }



      .video-desc-with-download {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      width: 50%;

        .video-desc {
          max-height: 90%;
          overflow: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 16px 32px;

          h4 {
            @include font-style($font-main, 500, 20px, 30px)
          }

          p {
            @include font-style($font-main, 300, 16px, 24px)

          }

        }
      }
    }

  }

  .poster-title {
    height: auto;
  }

}

.mobile-desc {
  box-sizing: border-box;
  overflow: scroll;
  border-top: 1px $color-mobile-desc-border solid;
  margin:0 32px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .mobile-desc-speakers{
    line-height: 22px;
  }

  .mobile-desc-p {
    height: auto;
    min-height: 200px;
    max-height: 700px;
    overflow: scroll;
  }
}

@media (max-width: mediaWight(desktopSm)) {
  .qsk-container-mobile {
    &__desc {
      line-height: 20px;
      margin-bottom: 48px;
    }
  }

  .hls-player-poster-container {
    width: 100%;
    @include flex(center, center);

    margin: 0 auto;
    height: fit-content;
    background: $bgColor-hls-player-poster-container;
  }
}