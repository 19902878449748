.upcoming-events {
  position: relative;

  &__back-icon {
    width: fit-content;
    height: 16px;

    position: absolute;
    top: 42px;
    left: 100px;

    cursor: pointer;

    padding: 12px;
    background-color: #fff;
    border-radius: 50%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}