@import "../../App";

.reminder {
  width: 100%;
  max-width: 674px;
  padding: 80px 90px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #ffffff;

  @media screen and (max-width: 1000px) {
    width: 80vw;
    padding: 60px 30px;
  }

  &__title {
    color: $color_four;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }

  &__subtitle {
    color: $color_four;
    font-family: $font-main;
    text-align: center;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 36px;
  }

  &__email-input {
    width: 100%;
    padding: 30px 24px;
    border-radius: 8px;
    background: #F3F4F8;
    margin-bottom: 20px;
    box-sizing: border-box;

    border: none;
    outline: none;
  }

  &__checkbox-wrap {
    margin-bottom: 45px;
    font-family: $font-main;
    color: $color_four;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1024px) {
      gap: 0;
    }
  }

  &__success-button {
    width: 100%;
    padding: 24px;

    border-radius: 8px;
    background: #A5CDF2;

    color: #282828;
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    border: none;
    outline: none;
    cursor: pointer;
  }

  &__success-container {
    width: 100%;
    max-width: 674px;
    padding: 85px 97px 63px 97px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #ffffff;

    @media screen and (max-width: 1000px) {
      width: 80vw;
      padding: 60px 30px;
    }
  }

  &__letter-icon-wrap {
    width: fit-content;
    margin: 0 auto 56px auto;
  }

  &__close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}

.reminder-modal-wrap {
  background-color: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
}

.hide-scroll {
  overflow: hidden;
}