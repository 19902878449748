@import "../../App";

.card {
  padding: 100px 0 0 0;

  @media (min-width: 768px) {
    padding: 80px 0 0 0;
  }

  @media (min-width: 1024px) {
    padding: 165px 0 0 0;
  }

  width: 72%;
  margin: 0 auto;

  @media (min-width: 1024px) and (max-width: 1768px) {
    width: 82%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 85%;
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: auto;
  }

  &__title {
    color: #000;
    font-size: 48px;
    font-weight: 700;
    text-align: center;

    margin-bottom: 48px;

    @media (min-width: 1024px) {
      text-align: start;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 1024px) {
      width: 100%;
      align-items: center;
      flex-direction: row;
      gap: 70px;
    }

    &--reverse {
      @media (min-width: 1024px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__poster {
    border-radius: 12px;
    width: 100%;
    height: auto;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  &__text-subtitle {
    color: $color-header-text;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    display: none;

    @media (min-width: 1024px) {
      display: block;
    }

    &--mobile {
      display: block;
      text-align: center;
      margin-bottom: 14px;

      @media (min-width: 1024px) {
        display: none;
      }
    }
  }

  &__text-title {
    color: #000;
    font-size: 20px;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 26px;
    }

    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }

  &__text-description {
    color: #000;
    font-size: 16px;
    line-height: 175%;

    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
}

.learn-more-type {
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
}

.learn-more-title {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.visit-website {
  width: fit-content;
  margin: 48px auto 0 auto;
  padding: 16px 24px;
  border-radius: 28px;
  border: 1px solid #000;
  color: #282828;
  text-decoration: none;


  @media (min-width: 1024px) {
    margin: 48px 0 0 0;
    border-radius: 6px;
    border: 1px solid rgba(76, 111, 255, 0.08);
    background: rgba(76, 111, 255, 0.08);
  }
}