.speaker-card {
  margin-top: 0;
  padding: 8px;
  cursor: pointer;
  
  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__name {
    color: var(--text-color-2, #191919);
    font-size: 18px;
    line-height: 175%;
  }

  &__user-type {
    color: rgba(25, 25, 25, 0.36);
    font-size: 16px;
    line-height: 100%;
  }
}