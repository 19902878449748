@import '../../App';

.event {
  width: 90%;
  max-width: 1760px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 80px 180px 80px;

  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 15px 40px 30px 40px;
  }

  &__back-icon {
    width: fit-content;
    height: 16px;

    position: absolute;
    top: 30px;
    left: 10px;

    cursor: pointer;

    padding: 12px;
    background-color: #fff;
    border-radius: 50%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__video-player {
    width: 100%;
    aspect-ratio: 1400 / 800;
    margin-bottom: 48px;
    border-radius: 12px;

    & > img {
      width: 100%;
      max-height: 800px;
    }
  }

  &__video-player-default {
    width: 100%;
    aspect-ratio: 1400 / 800;
    margin-bottom: 48px;
    border-radius: 12px;

    background-color: #282828;
  }

  &__data-container {
    margin-bottom: 50px;
  }

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: 700;

    margin-bottom: 12px;

    @media screen and (min-width: 1024px) {
      font-size: 48px;
    }
  }

  &__date {
    color: rgba(0, 0, 0, 0.48);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

    margin-bottom: 48px;

    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }

  &__description-container {
    display: grid;
    gap: 52px;
    grid-template-rows: auto auto auto;
    position: relative;

    @media screen and (min-width: 1024px) {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 2fr 1fr;
    }
  }

  &__speakers-container-with-button {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__speakers-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 1024px) {
      position: static;
    }
  }

  &__interaction-button-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__interaction-button {
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background: #FFF;

    display: flex;
    align-items: center;
    gap: 16px;

    color: var(--wl-colors-main-black, #282828);
    font-size: 18px;
    font-weight: 500;
    line-height: 175%;

    border: none;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      max-width: 480px;
      margin: 0 auto;
    }

    &--reminder {
      justify-content: center;
    }
  }

  &__navigation_button-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &__navigation_button {
    display: block;
    box-sizing: border-box;

    width: 100%;
    max-width: 480px;
    padding: 24px;

    border-radius: 8px;
    background: #A5CDF2;

    color: #282828;
    font-size: 20px;
    font-weight: 500;

    border: none;
    outline: none;
    cursor: pointer;

    &--white {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      background-color: #fff;
      margin: 0 auto;
    }
  }

  &__view-all {
    color: rgba(25, 25, 25, 0.36);
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
  }

  &__decorative-line {
    width: 100%;
    height: 1px;
    background-color: #A5A5A8;
    margin-top: -26px;
    max-width: 480px;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__background-img {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 100%;
      max-width: 500px;
    }
  }

  &__social-links-container {
    width: fit-content;
    padding: 13px;

    position: absolute;
    z-index: 2;
    right: 50%;
    transform: translateX(50%);
    top: 160px;

    &--adaptive {
      right: 50%;
      transform: translateX(50%);
      top: 265px;
    }
  }

  &__sponsors-wrap {
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;

    width: fit-content;
    max-width: 1600px;
    margin: 0 auto 80px;

    grid-row-start: 3;
    grid-row-end: 4;

    @media screen and (min-width: 1024px) {
      grid-column-start: 1;
      grid-column-end: 4;

      margin: 0 auto 120px;
    }
  }
}

.html-renderer-style {
  color: black;

  & > p {
    margin-bottom: 15px;
  }
}

.speakers-overlay-main {

  position: relative;

  .speakers-overlay-button {

  }

  .speakers-overlay-button:hover ~ .speakers-overlay {
    display: flex;
  }

  .speakers-overlay {
    position: absolute;
    display: none;
    top: 60px;
    left: 0;
    z-index: 12;
    width: 250px;
    border-radius: 8px;
    background: $color-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    padding: 24px 16px;

    flex-direction: column;
    gap: 14px;

    &:hover {
      display: flex;
    }

    .speaker-title {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;

      .speaker-name {
        .name {
          color: var(--wl-colors-main-black, #282828);
          font-size: 18px;
          font-weight: 400;
          line-height: 175%;
        }

        .type {
          color: var(--wl-colors-3rd-black-50, rgba(40, 40, 40, 0.50));
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }
  }

  .speaker-bio {
    color: var(--wl-colors-3rd-black-50, rgba(40, 40, 40, 0.50));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0;
  }

  .speakers-links {
    height: 20px;
    display: flex;
    margin-left: 0;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
}