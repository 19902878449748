@import '../../../App';

.speakers-agenda-container{
  display: flex;
  flex-wrap: wrap;
  line-height: 21px;
}
.event-detail-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.event-detail-head {
    display: flex;
    padding: 24px 20px;
    align-items: center;
    gap: 40px;
    background-color: $child-titleColor;
    color: $child-titleTextColor;
    p {
        @include font-style($font-second, 700, 20px, 28px);
    }
}

.agenda-detail-desc {
  height: auto;
}
.event-detail-title {
  @include flex(none, center);
  @include font-style($font-second, 700, 20px, 32px);
  padding: 14px 16px;
  background: $color-background;
  color: $color-text-main;
  height: 48px;

  span {
    margin: 0 auto;
  }

}
.event-detail-inside {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow: overlay;

  .agenda-detail-title{
    @include flex(space-between, center);
  }

    .event-detail-topic {
        span {
            @include font-style($font-main, 500, 18px, 20px)
        }

        @include font-style($font-second, 500, 20px, 20px)
    }
}

.agenda-detail-time-container {
  @include flex(none, center);
  gap: 16px;

  .agenda-detail-time {
    display: flex;
    gap: 12px;
    flex-direction: column;

        .agenda-detail-date {
            @include font-style($font-main, 500, 16px, 16px);
            color: rgba(0, 0, 0, 0.5);
        }

    }

    .agenda-detail-date-time {
        @include font-style($font-main, 500, 20px, 20px)
    }

  .agenda-detail-desc-watch-btn{
    @include flex(center,center);
    color: $color-agenda-detail-desc-watch-btn;
    background: $bgColor-agenda-detail-desc-watch-btn;
    padding: 8px 15px 8px 0;
    border-radius: 8px;
    font-weight: 700;
    margin-left: 8px;

    &:hover{
      background: $color-agenda-detail-desc-watch-btn;
    }

  }
}

.agenda-item-option {
    button:first-child {
            width: 100%;
    }
}

.agenda-detail-chairs,
.agenda-detail-speakers {
    .agenda-detail-speaker-head {
        @include font-style($font-main, 600, 20px, 20px)
    }
  h2{
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;
}
.agenda-detai-viewall {
  cursor: pointer;
  color:$color-grey-shade ;
  margin-top: 16px;

  &:hover {
    color: $color-blue;
  }
}

.agenda-detail-desc {
    p {
        text-align: justify;
        @include font-style($font-main, 400, 16px, 22px);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    b {
        @include font-style($font-main, 800, 16px, 22px)
    }

    .agenda-detail-desc-head {
        margin-bottom: 16px;
        @include font-style($font-main, 600, 20px, 28px);
    }
}

@media (min-width: 1025px) and (max-width: 1768px) {
  .event-detail-title {
    @include flex(start, center);
    @include font-style($font-second, 700, 24px, 28px)

  }
  .event-detail-head {
    padding: 16px;

        p {
            @include font-style($font-second, 700, 18px, 24px);
        }

  }

  .event-detail-inside {

    padding: 16px;
    gap: 32px;


    .agenda-detail-status-online{
      color: black;
    }

    .event-detail-topic {
      span {
        @include font-style($font-main, 500, 16px, 20px)
      }

      @include font-style($font-second, 500, 16px, 20px)
    }
  }
  .agenda-detail-chairs,
    .agenda-detail-speakers {
        .agenda-detail-speaker-head {
            @include font-style($font-main, 600, 16px, 20px)
        }

    .agenda-detai-viewall {
      cursor: pointer;

      :hover {
        color: $color-agenda-detai-viewall;
      }
    }

    gap: 16px;

    .speaker-card {
      @include flex(none, center);
      gap: 12px;

      img {
        border-radius: 50%;
        width: 46px;
        height: 46px;
      }

            div {
                p:first-child {
                    @include font-style($font-main, 500, 14px, 20px);
                }

                p {
                    @include font-style($font-main, 400, 12px, 20px);
                }
            }
        }
    }

    .agenda-detail-desc {
        p {
            @include font-style($font-main, 400, 14px, 20px)
        }

        .agenda-detail-desc-head {
            @include font-style($font-main, 600, 16px, 20px);
        }
    }

  .agenda-detail-time-container {
    svg {
      width: 48px;
    }

    gap: 16px;

    .agenda-detail-time {
      gap: 8px;

            .agenda-detail-date {
                @include font-style($font-main, 500, 14px, 16px);
            }
        }

        .agenda-detail-date-time {
            @include font-style($font-main, 500, 16px, 20px)
        }
    }
}

@media screen and (max-width: mediaWight(desktopSm)){
  .agenda-detail-chairs,
  .agenda-detail-speakers{
    max-height: 500px;
    overflow-y: scroll;
  }
}