.modal-bg {
  background-color: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
}

.event-modal {
  width: 100%;
  max-width: 500px;
  padding: 80px 90px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 10px;
  background-color: #ffffff;

  &__title {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  &__success-button {
    width: 100%;
    padding: 24px;

    border-radius: 8px;
    background: #A5CDF2;

    color: #282828;
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    border: none;
    outline: none;
    cursor: pointer;
  }

  &__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.hide-scroll {
  overflow: hidden;
}